<!-- App.vue -->

<template>
  <!-- 第一部分 -->
  <section>
    <div class="bg-white py-32 sm:py-32">
    <div class="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
      <!-- <div class="mx-auto max-w-3xl text-center ">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About Us</h2>
        <p class="mt-4 text-gray-500">As a digital creative, your laptop or tablet is at the center of your work. Keep your device safe with a fabric sleeve that matches in quality and looks.</p>
      </div> -->


      <div class="mx-auto max-w-2xl text-center  mb-8">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">About Us </h2>
        <p class="mt-6  mb-24 text-md leading-8 text-gray-600">As a digital creative, your laptop or tablet is at the center of your work. Keep your device safe with a fabric sleeve that matches in quality and looks.</p>
      </div>

      <div class="mt-16 space-y-16">
        <div v-for="(feature, featureIdx) in features" :key="feature.name" class="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8">
          <div :class="[featureIdx % 2 === 0 ? 'lg:col-start-1' : 'lg:col-start-8 xl:col-start-9', 'mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4']">
            <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ feature.name }}</h1>
            <p class="mt-6  mb-24 text-md leading-8 text-gray-600">{{ feature.description }}</p>
          </div>
          <div :class="[featureIdx % 2 === 0 ? 'lg:col-start-6 xl:col-start-5' : 'lg:col-start-1', 'flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8']">
            <div class="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100">
              <img :src="feature.imageSrc" :alt="feature.imageAlt" class="object-cover object-center" />
            </div>
          </div>
        </div>
      </div>

      
      
    </div>
  </div>
  </section>
  <!-- 第一部分 -->

  <!-- 第二部分 -->
  <section>
      <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-4 lg:px-8  pb-32">
        <div class="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
          <div class="w-full lg:max-w-lg lg:flex-auto">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Core Values</h2>
            <p class="mt-6 text-xl leading-8 text-gray-600">Through these core values, AgencyMR will continue to provide high-quality data solutions to clients worldwide, helping them succeed in an ever-changing market environment. We invite you to join us in exploring the power of data.</p>
            <img src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1344&h=1104&q=80" alt="" class="mt-16 aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]" />
          </div>
          <div class="w-full lg:max-w-xl lg:flex-auto">
            <h3 class="sr-only">Job openings</h3>
            <ul class="-my-8 divide-y divide-gray-100">
              <li v-for="opening in jobOpenings" :key="opening.id" class="py-8">
                <dl class="relative flex flex-wrap gap-x-3">
                  <dt class="sr-only">Role</dt>
                  <dd class="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
                    <a :href="opening.href">
                      {{ opening.role }}
                      <span class="absolute inset-0" aria-hidden="true" />
                    </a>
                  </dd>
                  <dt class="sr-only">Description</dt>
                  <dd class="mt-2 w-full flex-none text-base leading-7 text-gray-600">{{ opening.description }}</dd>
                  <dt class="sr-only">Salary</dt>
                  <!-- <dd class="mt-4 text-base font-semibold leading-7 text-gray-900">{{ opening.salary }}</dd> -->
                  <dt class="sr-only">Location</dt>
                  <dd class="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                    <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 flex-none fill-gray-300" aria-hidden="true">
                      <circle cx="1" cy="1" r="1" />
                    </svg>
                    <!-- {{ opening.location }} -->
                  </dd>
                </dl>
              </li>
            </ul>
            <!-- <div class="mt-8 flex border-t border-gray-100 pt-8">
              <a href="#" class="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">View all openings <span aria-hidden="true">&rarr;</span></a>
            </div> -->
          </div>
        </div>
      </div>
  </section>
  <!-- 第二部分 -->

  <div class="bg-indigo-100">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Learn how we can help you?<br />We’ll connect you with a AgencyMR expert!</h2>
      <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
        <a href="/contact" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
        <a href="/Enterprise_Solutions" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
      </div>
    </div>
  </div>
</template>




  
  <script setup>
import { defineComponent, h, ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
// 第一部分
const features = [
  {
    name: 'Redefining Data Services',
    description:
      'AgencyMR is a customer-centric data services company dedicated to helping clients succeed through innovative technology and precise data. We believe that accurate data is not only the foundation of decision-making but also the core driver of business growth. Since our inception, we have consistently focused on creating value for our clients through rigorous analysis, transparent operations, and unparalleled customer support.',
    imageSrc: require('./assets/images/z7.jpg'),
    imageAlt: 'About us',
  },
  {
    name: 'Our Vision and Mission',
    description:
      'Our vision is to become the world’s leading data service provider, offering reliable data support to clients across various industries. We are committed to helping clients uncover market trends, understand consumer behavior, and make informed business decisions. Through continuous innovation and a commitment to excellence, we aim to deliver ongoing value and growth for our clients.',
    imageSrc: require('./assets/images/z14.jpg'),
    imageAlt: 'About us',
  },
]
// 第二部分
const jobOpenings = [
  {
    id: 1,
    role: 'Integrity and Transparency:',
    href: '#',
    description:
      'We uphold the highest standards of ethical conduct, ensuring the transparency and reliability of all our data and analyses.',
    salary: '$75,000 USD',
    location: 'San Francisco, CA',
  },
  {
    id: 2,
    role: 'Innovation and Progress:',
    href: '#',
    description:
      'We constantly seek new ways to improve our services and technologies to ensure we remain at the forefront of the industry.',
    salary: '$125,000 USD',
    location: 'San Francisco, CA',
  },
  {
    id: 3,
    role: 'Customer First:',
    href: '#',
    description:
      'We prioritize our clients’ needs and are dedicated to providing exceptional service and support to help them stand out in the competitive market.',
    salary: '$105,000 USD',
    location: 'San Francisco, CA',
  },
  {
    id: 4,
    role: 'Teamwork:',
    href: '#',
    description:
      'We believe diversity and collaboration are the sources of innovation. Our team is committed to growing and succeeding together.',
    salary: '$105,000 USD',
    location: 'San Francisco, CA',
  },
]

  </script>
  <script>
  
  </script>
  
  <style>

  </style>
  